<template>
  <aside>
    <header>
      <h2>New Nomination Campaign</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>

      <div class="setting-row">
        <div class="title">
          <h4>Campaign name</h4>
        </div>
        <div class="fields">
          <input type="text" class="form-control" :class="{'is-invalid': $v.name.$error}" v-model="$v.name.$model" />
          <div class="invalid-feedback">Campaign name is required.</div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Participating channel</h4>
        </div>
        <div class="fields">
          <v-select v-model="$v.group.$model" :class="{'is-invalid': $v.group.$error}" :options="groups" label="title" :clearable="false" placeholder="Select a channel..." :reduce="opt => opt.id" />
          <div class="invalid-feedback">A channel is required.</div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Allow multiple users per nomination?</h4>
        </div>
        <div class="fields">
          <ht-switch v-model="allow_multiple_users" />
        </div>
      </div>

      <div class="setting-row award-selector">
        <div class="title">
          <h4>Awards up for nomination</h4>
          <p>Awards for which users may nominate their teammates for.</p>
        </div>
        <div class="fields">
          <div class="form-group">
            <v-select v-model="$v.awards.$model" :class="{'is-invalid': $v.awards.$error}" :options="available_awards" label="name" :clearable="false" :multiple="true" placeholder="Select awards..." :reduce="opt => opt.id">
              <template #option="option">
                {{option.name}}
                <span class="point-amount">{{$formatPoints(option.points)}}</span>
              </template>
            </v-select>
            <div class="invalid-feedback">At least one award is required.</div>
          </div>

          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="multi_nominations" id="customCheck1">
            <label class="custom-control-label" for="customCheck1">Allow more than one nomination per award?</label>
          </div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Campaign end date</h4>
          <p>Automatically stop accepting nominations on a specific date. You'll still preview nominations before announcing the winners.</p>
        </div>
        <div class="fields">
          <date-range-picker :single-date-picker="true" :min-date="$today.toJSDate()" :ranges="false" v-model="end_date">
            <template #input="{startDate}">
              <span class="placeholder" v-if="startDate === null">(optional)</span>
              <span v-else><i class="fas fa-calendar"></i> {{startDate.toLocaleDateString()}} <a href="#" @click.prevent.stop="end_date.startDate=null;"><i class="fas fa-times-circle"></i></a></span>
            </template>
          </date-range-picker>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Custom fields</h4>
          <p>Add additional fields to your nomination form.</p>
        </div>
        <div class="fields">
          <ul v-if="custom_fields.length > 0" class="custom-field-builder">
            <li v-for="(cf, cfindex) in $v.custom_fields.$each.$iter" :key="`customfield_${cfindex}`">
              <a href="#" @click.prevent="$v.custom_fields.$model.splice(cfindex, 1)"><i class="fas fa-trash"></i></a>
              <div class="input-wrap">
                <label>Field label</label>
                <input :class="['form-control', {'is-invalid': cf.label.$error}]" type="text" placeholder="Enter label here..." v-model="cf.label.$model">
                <div class="invalid-feedback">Cannot be blank.</div>
              </div>
              <div class="input-wrap" v-if="cf.type.$model === 'MULTIPLE_CHOICE'">
                <label>Options</label>
                <textarea :class="['form-control', {'is-invalid': cf.options.$error}]" v-model="cf.options.$model" placeholder="One option per line..." />
                <div class="invalid-feedback">Requires at least two items.</div>

                <label>
                  <input type="checkbox" v-model="cf.allow_multiple.$model" :value="true">
                  Allow multiple selections
                </label>
              </div>
            </li>
          </ul>
          <div class="dropdown">
            <button type="button" class="btn btn-circle btn-xs btn-dark" data-toggle="dropdown"><i class="fas fa-plus"></i></button>

            <div class="dropdown-menu">
              <button class="dropdown-item" type="button" @click="addCustomField('TEXT')">Text field</button>
              <button class="dropdown-item" type="button" @click="addCustomField('TEXTAREA')">Text area</button>
              <button class="dropdown-item" type="button" @click="addCustomField('MULTIPLE_CHOICE')">Multiple choice</button>
            </div>
          </div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Announcement message</h4>
          <p>A custom message attached to the nomination announcement in the Slack channel.</p>
        </div>
        <div class="fields">
          <textarea class="form-control" v-model="message" rows="4"></textarea>
        </div>
      </div>


    </main>

    <footer>
      <button class="btn btn-sm btn-green" :class="{'loading': loading}" @click="createCampaign">Create campaign</button>
    </footer>
  </aside>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import {required, minLength, requiredIf} from 'vuelidate/lib/validators'

export default {
  components: {
    DateRangePicker
  },
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    available_awards() {
      if(this.$store.state.awards === null) return null;
      return _.filter(this.$store.state.awards, a => !a.is_bot);
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  validations: {
    name: {
      required,
    },
    group: {
      required,
    },
    awards: {
      required,
      minLength: minLength(1)
    },
    custom_fields: {
      $each: {
        label: {
          required
        },
        type: {
          required
        },
        options: {
          lines: function(value, model) {
            if(model.type !== 'MULTIPLE_CHOICE') return true;
            console.log(model.options)
            if(!model.options) return false;
            return model.options.split("\n").length > 1 ? true : false;
          }
        },
        allow_multiple: {
          
        }
      }
    }
  },
  data() {
    return {
      name: '',
      awards: [],
      group: null,
      multi_nominations: false,
      allow_multiple_users: false,
      message: '',
      end_date: {
        startDate: null
      },
      custom_fields: []
    }
  },
  methods: {
    addCustomField(type) {
      this.custom_fields.push({
        type,
        label: '',
        options: ''
      })
    },
    close() {
      this.$emit('done')
    },
    async createCampaign() {
      this.$v.$touch()
      if(this.$v.$invalid) return;

      this.$store.commit('loading', true)

      await this.$api.Nominations.create({
        title: this.name,
        description: this.message,
        relevant_awards: this.awards,
        user_group: this.group,
        end_time: this.$DateTime.fromJSDate(this.end_date.startDate).toJSDate(),
        multi_nominations: this.multi_nominations,
        custom_fields: this.custom_fields,
        allow_multiple_users: this.allow_multiple_users
      })

      this.$store.commit('loading', false)

      this.$emit('done', 'CAMPAIGN_CREATED');
    }
  }
}
</script>

<style lang="scss" scoped>
.award-selector {
  .point-amount {
    background: $green;
    color: #fff;
    border-radius: 3px;
    line-height: 10px;
    font-size: 10px;
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 2px 4px;
    vertical-align: 1px;
  }
}

.custom-field-builder {
  display: block;
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    margin: 0 0 10px;

    + li {
      border-top: 1px solid $light;
      padding: 10px 0 0;
    }

    > a {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: $red;
      text-decoration: none;
      margin: 31px 0 0;
    }

    > .input-wrap {
      padding: 0 3px;
      flex: 1;
      > label {
        font-weight: 700;
        font-size: 12px;
        margin: 0;
      }
    }
  }
}

.vue-daterange-picker {
  width: 180px;

  .reportrange-text {
    > .placeholder {
      color: $input-placeholder-color;
    }

    > span {
      line-height: 1.7em;
      display: block;
      position: relative;
      
      > i {
        color: $input-placeholder-color;
        font-size: 0.8em;
        margin: 0 5px 0 0;
        vertical-align: 1px;
      }

      > a {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
        height: 14px;
        width: 14px;
        font-size: 13px;
        line-height: 14px;
        color: $input-placeholder-color;
        text-decoration: none;
        opacity: 0;
        transform: translateX(10px);
        transition: opacity 0.2s $curve, transform 0.2s $curve;

        &:hover {
          color: darken($input-placeholder-color, 5%);
        }
      }
    }

    &:hover {
      > span {
        > a {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
}
</style>